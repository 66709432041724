:root {
    --container-bg-color: #333;
    --left-bg-color: rgba(41, 126, 172, 0.5);
    /* --left-button-hover-color: rgba(25, 138, 198, 0.4); */
    --right-bg-color: #2b2b2b66;
    /* --right-button-hover-color: rgba(92, 92, 92, 0.3); */
    --hover-width: 60%;
    --other-width: 40%;
    --speed: 1000ms;
}

.split-container {
    padding: 0;
    margin: 0;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    width: 100%;
    height: 100%;
    overflow-x: hidden;

    width: 100%;
    height: 100%;
    background: #333;
}

.split-container h1 {
    font-size: 4rem;
    color: #fff;
    position: absolute;
    left: 50%;
    top: 30%;
    transform: translateX(-50%);
    white-space: nowrap;
}

.split {
    position: absolute;
    width: 50%;
    height: 100%;
    overflow: hidden;
}

.split.left {
    left: 0;
    background: url("./music.jpg") center center no-repeat;
    background-size: cover;
}

.split.left:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: var(--left-bg-color);
}

.split.right {
    right: 0;
    background: url("./coding.jpg") center center no-repeat;
    background-size: cover;
}

.split.right:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: var(--right-bg-color);
}

.split.left,
.split.right,
.split.right:before,
.split.left:before {
    transition: var(--speed) all ease-in-out;
}

.hover-left .left {
    width: var(--hover-width);
}

.hover-left .right {
    width: var(--other-width);
}

.hover-right .right {
    width: var(--hover-width);
}

.hover-right .left {
    width: var(--other-width);
}

.hover-left .right:before {
    z-index: 2;
}

.hover-right .left:before {
    z-index: 2;
}

@media (max-width: 800px) {
    .split-container {
        overflow-y: hidden;
    }

    .split-container h1 {
        font-size: 2rem;
    }

    .button {
        width: 12rem;
    }

    .split {
        width: 100%;
        height: 50%;
    }

    .split.left {
        top: 0;
    }

    .split.right {
        bottom: 0;
    }

    .hover-left .left {
        height: var(--hover-width);
        width: 100%;
    }
    
    .hover-left .right {
        height: var(--other-width);
        width: 100%;
    }
    
    .hover-right .right {
        height: var(--hover-width);
        width: 100%;
    }
    
    .hover-right .left {
        height: var(--other-width);
        width: 100%;
    }
}

@media (max-height: 700px) {
    .button {
        top: 70%;
    }
}
